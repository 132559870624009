import React, {useState} from "react"
import ErrorMessageForPassword from "../ErrorMessageForPassword"
import FormInput from "../FormInput"
import FormPassword from "../FormPassword"
import "./ChangePassword.css"

const ChangePassword = (props) =>  {

    const [userNewPassword, setUserNewPassword] = useState(props.newPassword)
    const [userRepeatPassword, setUserRepeatPassword] = useState(props.newPasswordAgain)

    return (
        <div className="change-psw-wrap">
        <h2>
            Enter new password
        </h2>
        <form onSubmit={props.onResetPasswordSubmit}>
            {props.isCodeError && (
            <p className="error-msg">
                {props.changePasswordErrorMessage}
            </p>
            )}
            <FormInput
            label="Code"
            name="code"
            type="text"
            placeholder=""
            inputOnChange={props.onCodeChange}
            value={props.code}
            />
            <FormPassword
            label="New Password"
            name="new_password"
            showPassword={props.showPassword}
            toggleShowPassword={props.toggleShowPassword}
            placeholder=""
            inputOnChange={(e) => {
                props.onPasswordChange(e)
                setUserNewPassword(e.target.value)
            }}
            value={userNewPassword}
            errorMessage={<ErrorMessageForPassword newPassword={userNewPassword} />}
            isInValid={props.isPasswordError}
            />
            <FormPassword
            label="Enter New Password Again"
            name="new_password_again"
            showPassword={props.showPassword}
            toggleShowPassword={props.toggleShowPassword}
            placeholder=""
            inputOnChange={(e) => {
                props.onPasswordChange(e)
                setUserRepeatPassword(e.target.value)
            }}
            value={userRepeatPassword}
            errorMessage={"Your password should match with the above one."}
            isInValid={props.isRepeatPasswordError}
            />
            <div spacing={6}>
            <button
                className="signin-button"
                type="submit"
            >
                Change Password
            </button>
            </div>
        </form>
        </div>
    )
}
export default ChangePassword
