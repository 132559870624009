import React, {useState, useEffect} from "react"
import { Auth } from "aws-amplify"
import {Link, navigate} from "gatsby"
import {
  validateEmail,
  validatePhone,
  validatePassword,
} from "../utils/authHelperFuncs"
import ForgotPassword from "../components/ForgotPassword"
import ChangePassword from "../components/ChangePassword"
import OverlayLoading from "../components/OverlayLoading"
import Layout2 from "../layouts/layout2"

const ForgotPasswordPage = (props) => {

  const [username, setUsername] = useState("")
  const [countryCode, setCountryCode] = useState("+91")
  const [productId, setProductId] = useState("")
  const [frequency, setFrequency] = useState("")
  const [type, setType] = useState("")
  const [user, setUser] = useState("")
  const [code, setCode] = useState("")
  const [isCodeError, setIsCodeError] = useState(false)
  const [isForgotPasswordScreen, setIsForgotPasswordScreen] = useState(true)
  const [changePasswordErrorMessage, setChangePasswordErrorMessage] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [newPasswordAgain, setNewPasswordAgain] = useState("")
  const [isPasswordError, setIsPasswordError] = useState(false)
  const [isRepeatPasswordError, setIsRepeatPasswordError] = useState(false)
  const [showCountryCode, setShowCountryCode] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [isError, setIsError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingMsg, setLoadingMsg] = useState("")

  useEffect(() => {
    let productId = sessionStorage.getItem("productId")
    let type = sessionStorage.getItem("type")
    let freq = sessionStorage.getItem("freq")
    setProductId(productId)
    setFrequency(freq)
    setType(type)
  }, [])

  const loadingCallback = (isLoading, msg) => {
    setLoading(isLoading)
    setLoadingMsg(msg)
  }
  const onUserNameChange = (e) => {
    setUsername(e.target.value);
    if (isNaN(e.target.value) || e.target.value === "") {
      setShowCountryCode(false);
    } else {
      setShowCountryCode(true);
    }
    if (e.target.value === "") {
      setIsError(true);
      setErrorMessage("Enter a valid input");
    } else {
      setIsError(false);
      setErrorMessage("");
    }
  };
  const onPasswordChange = (e) => {
    if (e.target.name === "new_password") {
      setNewPassword(e.target.value)
      if (!validatePassword(e.target.value)) {
        setIsPasswordError(true)
      } else {
        setIsPasswordError(false)
      }
    }
    if (e.target.name === "new_password_again") {
      setNewPasswordAgain(e.target.value)
      if (newPassword !== e.target.value) {
        setIsRepeatPasswordError(true)
      } else {
        setIsRepeatPasswordError(false)
      }
    }
  }
  const onResetPasswordSubmit = async (e) => {
    e.preventDefault()
    if (!validatePassword(newPassword)) {
      setIsPasswordError(true)
      return true
    }
    if (newPassword !== newPasswordAgain) {
      setIsRepeatPasswordError(true)
      return true
    }
    try {
      loadingCallback(true, "changing your password...")
      const userName = isNaN(username) ? username : `${countryCode}${username}`
      await Auth.forgotPasswordSubmit(userName, code, newPassword)
      loadingCallback(false, "")
      navigate("/")
    } catch (err) {
      loadingCallback(false, "")
      setIsCodeError(true)
      setChangePasswordErrorMessage(err.message)
    }
  }
  const onForgotPasswordSubmit = async (e) => {
    if (e) e.preventDefault()

    if (username === "") {
      setIsError(true);
      setErrorMessage("Enter a valid input");
    } else {
      setIsError(false);
      setErrorMessage("");
    }
    
    try {
      const userName = isNaN(username) ? username : `${countryCode}${username}`
      loadingCallback(true, "sending the code...")
      await Auth.forgotPassword(userName)
      loadingCallback(false, "")
      setIsForgotPasswordScreen(false)
    } catch (err) {
      loadingCallback(false, "")
    }
  }
    return (
        <Layout2>
            <div className="courses-wrapper">
              <div className="design-wrapper">
                <div className="forgot-psw-wrap">
                    <div className="forget-psw-contain">
                    {isForgotPasswordScreen ? (
                        <ForgotPassword
                        username={username}
                        errorMessage={errorMessage}
                        showCountryCode={showCountryCode}
                        isError={isError}
                        onUserNameChange={onUserNameChange}
                        onForgotPasswordSubmit={onForgotPasswordSubmit}
                        codeChange={(country_code) => setCountryCode(country_code)}
                        />
                    ) : (
                        <ChangePassword
                        code={code}
                        newPassword={newPassword}
                        newPasswordAgain={newPasswordAgain}
                        changePasswordErrorMessage={changePasswordErrorMessage}
                        isCodeError={isCodeError}
                        isPasswordError={isPasswordError}
                        isRepeatPasswordError={isRepeatPasswordError}
                        onPasswordChange={onPasswordChange}
                        onCodeChange={(e) => setCode(e.target.value)}
                        onResetPasswordSubmit={onResetPasswordSubmit}
                        showPassword={showPassword}
                        toggleShowPassword={() => setShowPassword(!showPassword)}
                        />
                    )}
                    {isCodeError && (
                        <button
                        onClick={() => {
                            setIsCodeError(false)
                            onForgotPasswordSubmit()
                        }}
                        className="resend-code"
                        >
                        Resend code again
                        </button>
                    )}
                    <div className="forgot-psw-button">
                        <Link to={`/signin`}>Back to login</Link>
                    </div>
                    </div>
                </div>
              </div>
            </div>
            {/* <OverlayLoading loading={loading} loadingMsg={loadingMsg} /> */}
        </Layout2>
    )
}
export default (ForgotPasswordPage)
