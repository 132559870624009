import React from "react"
import CountryCodeSelect from "../CountryCodeSelect"
import "./ForgotPassword.css"

const ForgotPassword = (props) => {

    return (
        <div className="forgot-psw-content">
            <h2>
                Forgot your password?
            </h2>
            <p>
                Enter your Email or Phone number below and we will send a message to
                reset your password
            </p>
            <form onSubmit={props.onForgotPasswordSubmit}>
                <div>
                <label  className="username-input">
                    Email or Phone number or Username<span className="asterisk">*</span>
                    <div className="div-input flex">
                    {props.showCountryCode && <CountryCodeSelect codeChange={props.codeChange} />}
                    <input
                        id="email-phone"
                        type="text"
                        name="username"
                        value={props.username}
                        onChange={props.onUserNameChange}
                        placeholder="Email or Phone number or Username"
                        label="Email or Phone number or Username"
                        className={`${props.errorMessage ? "outline-red" : ""}`}
                    />
                    </div>
                </label>
                <span className="error-msg">{props.errorMessage}</span>
                </div>
                <div spacing={6}>
                <button
                    type="submit"
                    className="signin-button"
                >
                    Reset my password
                </button>
                </div>
            </form>
        </div>
    )
}
export default (ForgotPassword)
